import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import { graphql } from "gatsby"
import TimesSection from "../components/pages/bokning/times-section"

export const query = graphql`
  query TimesQuery {
    prismic {
      allTimess {
        edges {
          node {
            hero_title
            hero_information
          }
        }
      }
    }
  }
`
const BastuTider = ({ data }) => {
  const node = data.prismic.allTimess.edges[0].node
  return (
    <Layout transparent currentSiteUrl='/bastu-tider'>
      <SEO title='Bastu-tider' />
        <TimesSection node={node} />
    </Layout>
  )
}
export default BastuTider
