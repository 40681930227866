import React from "react"
import styled from "styled-components"
import AvailableTimes from "../available-times"
import HoverLine from "../../../common/hover-line"
import { RichText } from 'prismic-reactjs';

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 5rem 0rem 10rem 0;
`
const InnerWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const TimesWrapper = styled.div`
  display: flex;
  max-width: 100vw;
  flex-direction: column;
`
const TitleWrapper = styled.div`
  margin-left: 2rem;
  margin-bottom: 2rem;
  width: 40rem;
  max-width: 80%;
`

const TimesSection = ({node}) => {
  console.log(node)
  return (
    <Wrapper>
      <InnerWrapper>
          <div style={{ width: "400px" }}>
            <h1>{RichText.asText(node.hero_title)}</h1>
            {RichText.render(node.hero_information)}
          </div>
        <TimesWrapper>
          <TitleWrapper>
            <h1>Bastutider</h1>
            <HoverLine show size='0.1' bottom />
          </TitleWrapper>
          <AvailableTimes />
        </TimesWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default TimesSection
