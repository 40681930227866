import React, { useState, useEffect } from "react"
import moment from "moment"
import {
  remapEvents,
  mapTable,
  getColorFromString,
} from "../../../../helpers/calenader-helper"
import styled from "styled-components"
import { Box } from "@material-ui/core"
import { FaVenus, FaVenusMars, FaMars, FaHammer } from "react-icons/fa"
import { TiGroup } from "react-icons/ti"
import { GiBroom } from "react-icons/gi"
import BookingData from "./booking-data"

const times = [
  { start: 6, end: 7 },
  { start: 7, end: 8 },
  { start: 8, end: 9 },
  { start: 9, end: 10 },
  { start: 10, end: 11 },
  { start: 11, end: 12 },
  { start: 12, end: 13 },
  { start: 13, end: 14 },
  { start: 14, end: 15 },
  { start: 15, end: 16 },
  { start: 16, end: 17 },
  { start: 17, end: 18 },
  { start: 18, end: 19 },
  { start: 19, end: 20 },
  { start: 20, end: 21 },
  { start: 21, end: 22 },
  { start: 22, end: 23 },
]

const days = ["Mån", "Tis", "Ons", "Tors", "Fre", "Lör", "Sön"]

const colors = {
  Damer: {
    hoverColor: "rgba(222, 225, 221, 1)",
    color: "rgba(222, 225, 221, 0.85)",
    icon: <FaVenus fontSize='medium' />,
  },
  Sambastu: {
    hoverColor: "rgba(109, 145, 151, 1)",
    color: "rgba(109, 145, 151, 0.85)",
    icon: <FaVenusMars fontSize='medium' />,
  },
  "Familje-bastu": {
    hoverColor: "rgb(101, 139, 111, 1)",
    color: "rgb(101, 139, 111,0.85)",
    icon: <TiGroup fontSize='medium' />,
  },
  Herrar: {
    hoverColor: "rgba(10, 41, 57, 1)",
    color: "rgba(10, 41, 57, 0.85)",
    icon: <FaMars style={{ color: "white" }} fontSize='medium' />,
  },
  Städning: {
    hoverColor: "rgba(117, 26, 26, 1)",
    color: "rgba(117, 26, 26, 0.85)",
    icon: <GiBroom style={{ color: "white" }} fontSize='medium' />,
  },
  Arbete: {
    hoverColor: "rgba(117, 26, 26, 1)",
    color: "rgba(117, 26, 26, 0.85)",
    icon: <FaHammer style={{ color: "white" }} fontSize='medium' />,
  },
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Table = styled.table`
  border-spacing: 0.3rem;
  width: 680px;
  height: 600px;
  max-width: 95vw;
  table-layout: fixed;
`
const RowHeader = styled.td`
  font-weight: bold;
  font-size: 16px;
  min-width: 5rem;
  text-align: center;
`

const HelperWrapper = styled.div`
  max-width: 100vw;
  padding-top: 1rem;
`

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: 11rem;
  align-items: center;
`
const ColorTextWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  width: 9rem;
  height: 3rem;
  border-radius: 3px;
`

const AvailableTimes = () => {
  const [tableData, setTableData] = useState(null)

  const getColor = col => {
    if (colors[col]) {
      return colors[col]
    }
    return {
      hoverColor: getColorFromString(col + "hover"),
      color: getColorFromString(col),
    }
  }

  useEffect(() => {
    var currentDate = moment()
    var weekStart = currentDate.clone().startOf("isoWeek")

    var _days = []

    for (var i = 0; i <= 6; i++) {
      let dateTime = moment(weekStart).add(i, "days")
      _days.push({ dateTime: dateTime, label: dateTime.format("ddd") })
    }

    const CALENDAR_ID = "36p6450cs0ubrrsbqa8d2tf7ss%40group.calendar.google.com"
    const API_KEY = "AIzaSyDIyZz_z9jF854erCn2GMRFKHyld6Gebxg"
    const fullPath = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`
    fetch(fullPath)
      .then(response => {
        return response.json()
      })
      .then(dataIn => {
        let events = remapEvents(dataIn)
        return setTableData(mapTable(_days, times, events))
      })
  }, [])

  const ColorHelper = () => {
    return (
      <HelperWrapper>
        <Box display='flex' flexDirection='row' flexWrap='wrap'>
          {Object.keys(colors).map(col => {
            const color = getColor(col)
            return (
              <ColorWrapper key={col}>
                <IconWrapper
                  style={{
                    backgroundColor: color.color,
                  }}
                >
                  {color.icon ? color.icon : ""}
                </IconWrapper>
                <ColorTextWrapper>{col}</ColorTextWrapper>
              </ColorWrapper>
            )
          })}
        </Box>
      </HelperWrapper>
    )
  }
  return (
    <Wrapper>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Table>
          <thead>
            <tr>
              <th></th>
              {days.map(day => (
                <th key={day} style={{ fontSize: "16px" }}>
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <RowHeader>{`${row.start}-${row.end}`}</RowHeader>
                  {row.columns.map((column, colIndex) => {
                    const color = column
                      ? getColor(column.key)
                      : getColor("nope")
                    return (
                      <BookingData
                        key={`${rowIndex}-${colIndex}`}
                        color={color}
                        column={column}
                        row={row}
                        day={days[colIndex]}
                      />
                    )
                  })}
                </tr>
              ))}
          </tbody>
        </Table>
        <ColorHelper />
      </Box>
    </Wrapper>
  )
}
export default AvailableTimes
