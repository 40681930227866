import React, { useState } from "react"
import styled from "styled-components"
import { DEFAULT_BLUE } from "../../../../styles/common"

const Wrapper = styled.td`
  font-size: 20px;
  text-align: center;
  height: 10px;
  width: 40px;
  &:hover {
    cursor: help;
  }
`
const Tooltip = styled.div`
  position: absolute;
  background-color: lightgrey;
  border-radius: 0.3rem;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.85);
  color: ${DEFAULT_BLUE};
  font-size: 1.6rem;
  padding: 1rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  ${Wrapper}:hover & {
    opacity: 1;
  };
  pointer-events: none;
`

const BookingData = ({ color, column, row, day }) => {
  const [hover, setHover] = useState(false)

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={
        column
          ? { backgroundColor: hover ? color.hoverColor : color.color }
          : {}
      }
    >
      {column && column.first && color.icon ? color.icon : ""}
      <Tooltip>{column && column.key && row ? <>{day} {row.start}-{row.end} <br/> {column.key}</> : ""}</Tooltip>
    </Wrapper>
  )
}

export default BookingData
